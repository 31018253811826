import { ApiRequest, GetBaseServiceFunctions } from '../baseService'

const BaseRoute = 'app/ndis-service-items'

const baseFunctions = GetBaseServiceFunctions(BaseRoute)

function ExportSearchResult(payload = {}) {
  return ApiRequest.post(`${BaseRoute}/export`, payload, { responseType: 'arraybuffer' })
}
function GetListServiceItemRates(payload = {}) {
  return ApiRequest.get(`${BaseRoute}/item-rates`, {
    params: payload,
    paramsSerializer: {
      indexes: true,
    },
  })
}
const API = {
  ...baseFunctions,
  ExportSearchResult,
  GetListServiceItemRates,
}

export default API
