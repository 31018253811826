import './Tab.scss'

import cx from 'classnames'
import { observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import dashboardStore from '@stores/dashboard'
import { DashboardRouteService } from '../../routes-setting/dashboardRoute'
import useTab from './useTab'
import { useMemo } from 'react'
import AppIcon from '@components/AppIcon'

const TabCloseIcon = () => (
  <svg viewBox="0 0 14 14">
    <path strokeWidth="1.125" d="M4 4 L10 10 M10 4 L4 10" />
  </svg>
)

const Tab = ({ tabIndex, tabId }) => {
  const { tabs, tabDragdropState, closureOnTabMouseDown, closureCloseTab, setActiveTab } = dashboardStore
  const currentTab = tabs[tabIndex]
  const { dragging, index, x } = tabDragdropState
  const style = { zIndex: tabs.length - tabIndex, left: x[tabIndex] }
  const active = tabId === dashboardStore.currentTabId
  const animation = dragging && index !== tabIndex
  const tabData = useTab(tabId, dashboardStore.refreshKey)

  const routeSetting = useMemo(() => {
    return DashboardRouteService.getRouteByPathname(currentTab.path)
  }, [currentTab])

  if (!routeSetting) return null

  return (
    <Link
      to={currentTab.absUrl}
      style={style}
      className={cx('Tab', { active, animation })}
      onClick={e => {
        e.preventDefault()
        setActiveTab(currentTab.id)
      }}
    >
      <div className="Tab-Background" />
      <div className="Tab-Content" onMouseDown={closureOnTabMouseDown(tabIndex)} title={tabData?.title ?? ''}>
        <AppIcon
          name={routeSetting.icontab ? routeSetting.icontab : 'dashboard-tab'}
          className="icon text-xl mr-2"
          materialIcon
        />
        <span>{tabData?.title ?? ''}</span>
      </div>
      <div
        className="Tab-Close"
        onClick={e => closureCloseTab(e, currentTab.path, tabId)}
        onMouseDown={e => e.preventDefault()}
      >
        <TabCloseIcon />
      </div>
    </Link>
  )
}

export { TabCloseIcon }
export default observer(Tab)
