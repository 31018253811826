import cx from 'classnames'
import { observer } from 'mobx-react'
import ui from '@stores/dashboard'
import FullscreenIcon from './FullscreenIcon'
import ShowSidebarIcon from './ShowSidebarIcon'
import Tabs from './Tabs'
import AccountSwitcher from '@components/AccountSwitcher'
import SystemUpdate from '@components/SystemUpdate'
import ViewingUsers from '@modules/realtime-notification/components/ViewingUsers'

const DashboardHeader = () => {
  const {
    sidebar: { collapsed: sidebarAutoHide },
  } = ui

  return (
    <div
      className={cx(
        'fixed top-0 right-0 left-[350px] h-[3.75rem] min-w-[660px] z-[1000] [&.sidebarAutoHide]:sm:left-[5rem] transition-all duration-300',
        { sidebarAutoHide },
      )}
    >
      <Tabs />
      <div className="flex absolute top-0 right-0">
        <ViewingUsers />
        {/* <FullscreenIcon /> */}

        {/* <SystemUpdate /> */}
        <AccountSwitcher />
      </div>
      {sidebarAutoHide && <ShowSidebarIcon />}
    </div>
  )
}

export default observer(DashboardHeader)
