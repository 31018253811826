import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import AppModal from '.'
type Props = {
  onCancel?: () => void
  modalParams: any
  hideWarningIcon?: boolean
  avoidCancelOnSubmit?: boolean
  btnYesText?: string
  btnNoText?: string
}
const ModalConfirm = ({
  onCancel = () => {},
  modalParams,
  hideWarningIcon = false,
  avoidCancelOnSubmit = false,
  btnYesText = 'Yes',
  btnNoText = 'No, return',
}: Props) => {
  const {
    message,
    onOk,
    functionCallbackCancel,
    footerCloseButton,
    modelWidth = 600,
    title = '',
    isShowWarningIcon = true,
    customButton,
    bodyStyle = {},
  } = modalParams

  const handleOnOkClick = () => {
    !avoidCancelOnSubmit && onCancel()
    onOk && onOk()
  }

  const handleCancel = () => {
    functionCallbackCancel && functionCallbackCancel()
    onCancel()
  }

  return (
    <AppModal
      title={title}
      open={true}
      width={modelWidth}
      bodyStyle={bodyStyle}
      className="custom-popup-confirm"
      footer={
        <>
          {customButton && customButton}
          {footerCloseButton || !onOk ? (
            <Button onClick={handleCancel}>Close</Button>
          ) : (
            <>
              <Button className="button-yes" type="primary" onClick={handleOnOkClick}>
                {modalParams?.btnYesText ?? btnYesText}
              </Button>
              <Button className="button-cancel" onClick={handleCancel}>
                {modalParams?.btnNoText ?? btnNoText}
              </Button>
            </>
          )}
        </>
      }
    >
      <div className="custom-content-popup-confirm flex items-center flex-col">
        {!hideWarningIcon && isShowWarningIcon && (
          <ExclamationCircleOutlined style={{ color: 'orange', fontSize: 55 }} />
        )}
        <div className="modal-confirm-message mt-5">{message}</div>
      </div>
    </AppModal>
  )
}

export default ModalConfirm
