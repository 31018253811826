import AppModal from '@components/AppModal'
import ModalConfirm from '@components/AppModal/ModalConfirm'
import { Button, Form } from 'antd'
import cx from 'classnames'
import { useEffect, useState } from 'react'
import { AppButton } from '../AppButton'
import { AppFileUpload } from '../AppFileUpload'

import { DownloadOutlined } from '@ant-design/icons'
import ButtonLink from '@components/ButtonLink'
import { Flex } from '@components/Layout'
import TooltipInfo from '@components/TooltipInfo'
import { ApiService } from '@services/ApiService'
import appStore from '@stores/appStore'
import { FormLabelAlign } from 'antd/lib/form/interface'
import { ReactComponent as DownloadIcon } from 'assets/svg/download.svg'
import { observer } from 'mobx-react'

export const AppFormFileUpload = ({
  className = '',
  classNameUpload = '',
  label,
  suffixLabel,
  rules = null,
  validateStatus = null,
  help = null,
  required = false,
  labelAlign = 'left',
  labelCol,
  wrapperCol,
  dragMessages = 'Drag and drop here or Click to browse',
  name = 'file',
  type = [],
  description = null,
  multiUpload = false,
  maxSize = 100,
  previewTemplate = null,
  fileList = [],
  disabled = false,
  attachmentTypeId = null,
  isViewMergeFile = false,
  onChange = (files, file) => {},
  beforeUpload = () => {},
  disabledDelete = false,
  tenantId = null,
  tooltipMessage = '',
  onRemoveAll = () => {},
}: any) => {
  const [attachments, setAttachments] = useState([])
  const [isOpenAttachmentList, setIsOpenAttachmentList] = useState(false)
  const [key, setKey] = useState(Date.now())
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [currentFileIndex, setCurrentFileIndex] = useState(null)

  const handleOnChange = (files, file) => {
    setAttachments(files)
    onChange(files, file)
  }

  const beforeUpload_ = () => {
    if (!!attachments.length && !multiUpload) {
      handleRemoveFile(0)
    }
    beforeUpload && beforeUpload()
  }

  const handleRemoveFile = index => {
    const newAttachments = [...attachments]
    const file = newAttachments.splice(index, 1)
    setAttachments([...newAttachments])
    onChange(newAttachments, { ...file[0], status: 'removed' })
    if (newAttachments.length === 0) {
      setIsOpenAttachmentList(false)
    }
  }

  const renderTitle = () => {
    return fileList && !!fileList.length
      ? `(${fileList.length} ${fileList.length > 1 ? 'files' : 'file'})`
      : !isViewMergeFile
      ? disabled
        ? '(No file)'
        : ``
      : '(No Merge File)'
  }

  useEffect(() => {
    setAttachments(fileList)
  }, [fileList])

  const handleDownloadFile = file => {
    ApiService.File.Download(file)
  }

  const handleRemoveAllFiles = () => {
    appStore.openConfirmModal({
      message: 'Do you want delete all files?',
      onOk: () => {
        onRemoveAll()
      },
    })
  }

  return (
    <Form.Item
      className={cx([className, 'form-file-upload'])}
      label={''}
      rules={rules}
      validateStatus={validateStatus}
      help={help}
      required={required}
      labelAlign={labelAlign as FormLabelAlign}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
    >
      <div className={cx(['flex justify-between mb-3', classNameUpload])}>
        <span className="mt-2 w-full lg:w-auto flex items-center gap-2">
          <span>{label}</span>
          {renderTitle()}
          {tooltipMessage ? (
            <TooltipInfo text={tooltipMessage} />
          ) : (
            !multiUpload && <TooltipInfo text={'Only 1 document is allowed'} />
          )}
          {suffixLabel}
        </span>

        <Flex>
          <AppButton
            className="rounded-md"
            disabled={!fileList.length}
            color="blue"
            onClick={() => setIsOpenAttachmentList(true)}
          >
            View all
          </AppButton>
          <AppButton
            className="rounded-md"
            disabled={!fileList.length || appStore.isDemoAccount}
            color="green"
            onClick={() => ApiService.File.DownloadAll(fileList)}
          >
            <DownloadIcon className="mr-1" width="16" height="16" /> Download all
          </AppButton>
        </Flex>
        <AppModal
          open={isOpenAttachmentList}
          width={600}
          onCancel={() => setIsOpenAttachmentList(false)}
          footer={
            <AppButton color="red" outline onClick={() => setIsOpenAttachmentList(false)}>
              Close
            </AppButton>
          }
          title={
            <Flex className="justify-between">
              <span>{renderTitle()}</span>
              {!disabledDelete && attachments.length > 0 && (
                <ButtonLink color="red" className="text-base" onClick={() => handleRemoveAllFiles()}>
                  Remove All
                </ButtonLink>
              )}
            </Flex>
          }
        >
          <div className="py-2.5 [&>.file-item:nth-child(n+1)]:mt-3">
            {attachments.map((file, index) => (
              <div className="file-item flex items-center" key={index}>
                {!appStore.isDemoAccount && (
                  <Button
                    size="middle"
                    className="mr-3"
                    icon={<DownloadOutlined style={{ fontSize: 20 }} />}
                    onClick={e => {
                      e.preventDefault()
                      handleDownloadFile(file)
                    }}
                    title="Download file"
                    type="link"
                  />
                )}
                <div className="flex flex-1 justify-between items-center bg-[#F3F5F7] rounded pl-3 pr-1 py-1">
                  <div className="font-normal">
                    {file.name || file.fileName}
                    {file.numberOfPages ? ` - ${file.numberOfPages} ${file.numberOfPages > 1 ? 'pages' : 'page'}` : ''}
                    {file.creationTime ? ` - ${appStore.formatDateTime(file.creationTime)}` : ''}
                  </div>

                  {!disabledDelete && !appStore.isDemoAccount && (
                    <Button
                      icon={<>&#x2715;</>}
                      type="link"
                      style={{ color: '#F44336' }}
                      title="Delete file"
                      disabled={disabledDelete}
                      onClick={e => {
                        e.preventDefault()
                        setCurrentFileIndex(index)
                        setConfirmDelete(true)
                      }}
                    />
                  )}
                </div>
              </div>
            ))}
          </div>
        </AppModal>
      </div>
      {!disabled && (
        <AppFileUpload
          key={key}
          name={name}
          readonly={disabled || appStore.isDemoAccount}
          type={type}
          description={description ?? `(Max ${maxSize}MB)`}
          previewTemplate={previewTemplate}
          maxSize={maxSize}
          title={dragMessages}
          multiUpload={multiUpload}
          fileList={attachments}
          itemRender={() => <></>}
          attachmentTypeId={attachmentTypeId}
          onChange={handleOnChange}
          beforeUpload={beforeUpload_}
          tenantId={tenantId}
        />
      )}

      {confirmDelete && (
        <ModalConfirm
          modalParams={{
            message: 'Do you want to delete this file?',
            onOk: () => {
              if (currentFileIndex >= 0) {
                handleRemoveFile(currentFileIndex)
              }
            },
          }}
          onCancel={() => setConfirmDelete(false)}
        />
      )}
    </Form.Item>
  )
}

export default observer(AppFormFileUpload)
