import appStore from '@stores/appStore'
import { isExist } from '@utils/functions'
import { Form, Input, InputNumber } from 'antd'
import cx from 'classnames'
import { memo, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { AppDatePicker } from '../AppDatePicker'
import { AppDateRangePicker } from '../AppDatePickerRange'
import AppFileStaticUpload from '../AppFileStaticUpload'
import { FormContext } from '../AppForm'
import { AppInputGroup } from '../AppInputGroup'
import { Flex } from '@components/Layout'
import TooltipInfo from '@components/TooltipInfo'
import { ERROR_MESSAGES } from '@utils/constants'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import { FormLabelAlign } from 'antd/lib/form/interface'
import dayjs from 'dayjs'
import { AppCheckbox } from '../AppCheckbox'
import AppDateInfo from '../AppDateInfo'
import InputHint from '../InputHint'
import './style.scss'

const defaultParser = value => value.replace(/\$\s?|(,*)/g, '')

const phoneRule = { max: 16, message: 'Must be less than or equal to 16 digits' }
const emailRule = { type: 'email', message: ERROR_MESSAGES['email'] }

function formatPhoneNumber(value) {
  return (value ?? '').replace(/\D/g, '')
}

type InputType =
  | 'date'
  | 'text'
  | 'number'
  | 'currency'
  | 'percent'
  | 'dateRange'
  | 'time'
  | 'checkbox'
  | 'phone'
  | 'radio'
  | 'checkboxSingle'
  | 'textArea'
  | 'image'
  | 'file'
  | 'password'
  | 'email'

type Props = {
  className?: string
  classNameInput?: string
  inputWidth?: string
  type?: InputType
  name?: string
  label?: JSX.Element | string
  rules?: any[]
  validateStatus?: any
  help?: any
  required?: boolean
  labelAlign?: string
  labelCol?: any
  wrapperCol?: any
  size?: string
  disabled?: boolean
  options?: any[]
  formatter?: (value) => string
  parser?: (value) => void
  max?: number
  min?: number
  value?: any
  fractionDigits?: number
  onChange?: (event, value?) => void
  labelForCheckBox?: any
  initialValue?: any
  modeShowContent?: boolean
  prefixValue?: string
  showPrefix?: boolean
  suffixLabel?: string | JSX.Element
  enableTime?: boolean
  hint?: string
  onPressEnter?: (e) => void
  tooltip?: React.ReactElement
  detailTooltip?: React.ReactNode
  rounded?: boolean
  [x: string]: any
}

/**
 * AppFormInput component is a customizable input component for forms.
 *
 * @component
 * @example
 * return (
 *   <AppFormInput
 *     className="my-input"
 *     type="text"
 *     name="firstName"
 *     label="First Name"
 *     rules={[{ required: true, message: 'Please enter your first name' }]}
 *     onChange={(value) => console.log(value)}
 *   />
 * )
 *
 * @param {string} className - The custom class name for the component.
 * @param {string} classNameInput - The custom class name for the input element.
 * @param {string} inputWidth - The width of the input element.
 * @param {InputType} type - The type of input element.
 * @param {string} name - The name of the input element.
 * @param {JSX.Element | string} label - The label for the input element.
 * @param {any[]} rules - The validation rules for the input element.
 * @param {any} validateStatus - The validation status of the input element.
 * @param {any} help - The help message for the input element.
 * @param {boolean} required - Indicates if the input is required.
 * @param {string} labelAlign - The alignment of the label.
 * @param {any} labelCol - The column configuration for the label.
 * @param {any} wrapperCol - The column configuration for the input element.
 * @param {string} size - The size of the input element.
 * @param {boolean} disabled - Indicates if the input is disabled.
 * @param {any[]} options - The options for radio inputs.
 * @param {(value: any) => string} formatter - The formatter function for the input value.
 * @param {(value: string) => void} parser - The parser function for the input value.
 * @param {number} max - The maximum value for number inputs.
 * @param {number} min - The minimum value for number inputs.
 * @param {any} value - The value of the input element.
 * @param {number} fractionDigits - The number of fraction digits for number inputs.
 * @param {(event: React.ChangeEvent<HTMLInputElement>, value?: any) => void} onChange - The change event handler for the input element.
 * @param {any} labelForCheckBox - The label for checkbox inputs.
 * @param {any} initialValue - The initial value of the input element.
 * @param {boolean} modeShowContent - Indicates if the input should show its content instead of an input element.
 * @param {string} prefixValue - The prefix value for the input element.
 * @param {boolean} showPrefix - Indicates if the prefix value should be shown.
 * @param {string | JSX.Element} suffixLabel - The suffix label for the input element.
 * @param {boolean} enableTime - Indicates if the time should be enabled for date inputs.
 * @param {string} hint - The hint text for the input element.
 * @param {(e: React.KeyboardEvent<HTMLInputElement>) => void} onPressEnter - The key press event handler for the input element.
 * @param {React.ReactElement} tooltip - The tooltip for the input element.
 * @param {React.ReactNode} detailTooltip - The detailed tooltip for the input element.
 * @param {number} rows - The number of rows for textarea inputs.
 * @param {any} props - Additional props to be passed to the input element.
 */

export const AppFormInput = memo(
  ({
    className = '',
    classNameInput = '', // custom className for the input
    inputWidth = 'w-40',
    type = 'text', // type of input
    name, // read ant's document, please
    label = '', // read ant's document, please
    postfixLabel,
    rules = [], // read ant's document, please
    validateStatus, // read ant's document, please
    help, // read ant's document, please
    required = false, // read ant's document, please
    labelAlign = 'left', // read ant's document, please
    labelCol, // read ant's document, please
    wrapperCol, // read ant's document, please
    size = 'middle', // read ant's document, please
    disabled, // read ant's document, please
    options = [], // list of radio options
    parser = defaultParser,
    max = 10000000000,
    value,
    fractionDigits = null,
    onChange,
    labelForCheckBox = null,
    initialValue,
    modeShowContent,
    prefixValue = '',
    showPrefix = true,
    suffixLabel = '',
    hint,
    enableTime = false,
    format = 'DD MMM YYYY',
    onPressEnter,
    tooltip,
    detailTooltip,
    rows = 3,
    maxLength = 4000,
    disabledDate = null,
    ...props
  }: Props) => {
    const [inputValue, setInputValue] = useState(value)
    const formContext = useContext(FormContext)
    const [valueChanged, setValueChanged] = useState(false)
    const [isShowingTooltip, setIsShowingTooltip] = useState(false)
    const [isValueChanged, setIsValueChanged] = useState(false)

    const inputRules = [...rules]
    if (required) {
      inputRules.push({ required: true, message: 'This field is required' })
    }

    if (type === 'phone') {
      inputRules.push(phoneRule)
    }
    if (type === 'email') {
      inputRules.push(emailRule)
    }

    const renderLabel = () => {
      if (!label) {
        return hint ? <InputHint text={hint} /> : ''
      } else {
        return (
          <span className="w-full flex items-center gap-1">
            <span onClick={e => e.preventDefault()} className={cx(['form-item-label'])}>
              {label}
            </span>
            <span className="justify-center items-center flex gap-1">
              {type !== 'checkboxSingle' ? suffixLabel : ''} <InputHint text={hint} />
            </span>
          </span>
        )
      }
    }

    const formItemProps = {
      label: renderLabel(),
      validateStatus: validateStatus,
      help: help,
      labelAlign: labelAlign as FormLabelAlign,
      labelCol,
      wrapperCol,
      required: required,
      rules: inputRules,
      name: name,
      initialValue,
    }

    useEffect(() => {
      if (type === 'phone') {
        setInputValue(formatPhoneNumber(value))
      }

      if (type === 'number' || type === 'currency' || type === 'percent') {
        if (!!value && !isNaN(value)) {
          if (type === 'currency') {
            setInputValue(parseFloat(value).toFixed(2))
          } else {
            setInputValue(parseFloat(value))
          }
        } else {
          const value_ = props.min ?? formContext.getFieldValue(name) ?? value
          if (!!value_ && !isNaN(value_)) setInputValue(value_)
          else setInputValue(0)
        }
      } else if (type === 'date') {
        if (typeof value === 'string') {
          const isDateOnly = value.length === 10
          const isIgnoreTimeZone = !enableTime && (isDateOnly || (!isDateOnly && value.endsWith('T00:00:00Z')))
          if (isIgnoreTimeZone) {
            setInputValue(dayjs.utc(value))
          } else {
            setInputValue(appStore.getMomentDateTime(value))
          }
        }
      } else {
        setInputValue(value)
      }
    }, [value, type])

    const setValue = useCallback(
      v => {
        if (v !== inputValue) {
          setInputValue(v)
          setValueChanged(true)
        }
      },
      [inputValue],
    )
    const emitInputValue = useCallback(
      emitValue => {
        if (onChange) {
          if (name) {
            onChange(name, emitValue)
          } else {
            onChange(emitValue)
          }
        } else {
          if (name) {
            formContext.onFieldChange(name, emitValue)
          }
        }
      },
      [formContext, name, onChange],
    )

    const handleOnChange = useCallback(
      e => {
        setIsValueChanged(true)
        let newValue = e && e.target ? e.target.value : e
        if (type === 'checkbox' || type === 'radio') {
          emitInputValue(newValue)
        }
        if (type === 'date') {
          if (enableTime) {
            newValue = appStore.getMomentDateTime(newValue)?.startOf('day').toISOString()
          }

          emitInputValue(newValue)
        }

        if (type === 'number' || type === 'currency' || type === 'percent') {
          if (newValue === null) {
            setValue(props.min ?? null)
          } else if (newValue === 0 || Number(newValue)) {
            setValue(parseFloat(newValue))
          }
          return
        }

        if (type === 'checkboxSingle') {
          emitInputValue(e.target.checked)
        }

        if (type === 'phone') {
          setValue(formatPhoneNumber(newValue))
        }

        // eslint-disable-next-line eqeqeq
        if (inputValue !== newValue) {
          setValue(newValue)
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [emitInputValue, inputValue, type],
    )

    const handleOnChangeDateRange = useCallback(
      dateRange => {
        let newValue = dateRange && isExist(dateRange) ? dateRange : []

        emitInputValue(newValue)

        setValue(newValue)
        emitInputValue(newValue)

        if (onChange) {
          onChange(dateRange)
        }
      },
      [emitInputValue, onChange, setValue],
    )

    const onBlur = useCallback(
      e => {
        if (!isValueChanged) return

        let emitValue = inputValue
        if (type === 'phone') {
          emitValue = formatPhoneNumber(inputValue)
        }

        if (type === 'number' || type === 'currency' || type === 'percent') {
          emitValue = inputValue === null ? props.min ?? null : parseFloat(inputValue)
        }
        emitInputValue(emitValue)
      },
      [emitInputValue, inputValue, type, valueChanged],
    )

    const handleOnPressEnter = e => {
      e.stopPropagation()
      if (onPressEnter) onPressEnter(e.target.value)
    }

    const numberFormat = useCallback(
      value => {
        // if the value is already empty(null or undefined),
        // just return the empty string back and do nothing
        if (value === null || value === undefined || value === '') {
          return ''
        }

        let minimumFractionDigits = 0
        let maximumFractionDigits = fractionDigits || 0

        if (fractionDigits == null) {
          if (type === 'currency' || type === 'percent') {
            maximumFractionDigits = 2
            if (type === 'currency' && disabled) {
              minimumFractionDigits = 2
            }
          }
        } else {
          maximumFractionDigits = fractionDigits
        }

        let decimalPlace = value.split('.')
        if (decimalPlace[1]) {
          minimumFractionDigits =
            decimalPlace[1].length <= maximumFractionDigits ? decimalPlace[1].length : maximumFractionDigits
        }

        return Number(value).toLocaleString('en-US', {
          minimumFractionDigits: minimumFractionDigits,
          maximumFractionDigits: maximumFractionDigits,
        })
      },
      [fractionDigits, type],
    )

    const inputRef = useRef(null)

    const [dateRangePlacement, setDateRangePlacement] = useState(null)

    useEffect(() => {
      if (type === 'dateRange') {
        setTimeout(() => {
          const bound = inputRef.current?.getBoundingClientRect?.()

          if (!bound) return

          const placement =
            (bound.top > window.innerHeight - bound.bottom ? 'top' : 'bottom') +
            (bound.left > window.innerWidth - bound.right ? 'Right' : 'Left')

          setDateRangePlacement(placement)
        }, 200)
      }
    }, [])

    if (modeShowContent) {
      return (
        <Form.Item className={cx([className])} {...formItemProps}>
          <span className="Label-item">
            {type === 'date' ? (
              inputValue ? (
                <AppDateInfo date={inputValue} format={format} />
              ) : (
                'None'
              )
            ) : (
              inputValue ?? 'None'
            )}
          </span>
        </Form.Item>
      )
    }

    if (type === 'text' || type === 'phone' || type === 'email') {
      return (
        <Form.Item className={cx([className])} {...formItemProps}>
          <Input
            value={inputValue}
            onChange={handleOnChange}
            onBlur={onBlur}
            className={cx(['rounded-md', classNameInput])}
            size={size as SizeType}
            disabled={disabled || isShowingTooltip}
            onPressEnter={handleOnPressEnter}
            autoComplete="off"
            {...props}
            suffix={
              <>
                <span className="ml-2">{tooltip}</span>
                {(appStore.isStaff || appStore.isAdminStaff) && detailTooltip && (
                  <TooltipInfo
                    text={detailTooltip}
                    onOpenChange={visible => {
                      setIsShowingTooltip(visible)
                    }}
                  />
                )}
              </>
            }
          />
        </Form.Item>
      )
    }

    if (type === 'number' || type === 'currency' || type === 'percent') {
      let renderPrefixValue = prefixValue
      if (type === 'currency' && !prefixValue) {
        renderPrefixValue = '$'
      }

      if (type === 'percent' && !prefixValue) {
        renderPrefixValue = '%'
      }

      let inputStyle = { paddingLeft: renderPrefixValue ? '7px' : '0px' }
      if (props.style) {
        inputStyle = { ...inputStyle, ...props.style }
      }

      let numberValue = inputValue
      if (inputValue === null || inputValue === undefined) {
        if (name) {
          numberValue = formContext.getFieldValue(name)
        }
      }
      return (
        <Form.Item className={cx([className])} {...formItemProps}>
          {renderPrefixValue && showPrefix && (
            <span style={{ position: 'absolute', left: '8px', top: '8px', zIndex: '1', lineHeight: '22px' }}>
              {renderPrefixValue}
            </span>
          )}
          <InputNumber
            formatter={numberFormat}
            parser={parser}
            size={size as SizeType}
            value={numberValue}
            onChange={handleOnChange}
            onBlur={onBlur}
            className={cx(['rounded-md', inputWidth, classNameInput, renderPrefixValue && showPrefix ? 'pl-3' : ''])}
            disabled={disabled}
            onPressEnter={handleOnPressEnter}
            style={inputStyle}
            max={max}
            defaultValue={null}
            min={type === 'currency' ? 0 : props.min}
            {...props}
          />
        </Form.Item>
      )
    }

    if (type === 'date') {
      return (
        <Form.Item className={cx([className])} {...formItemProps}>
          <AppDatePicker
            value={inputValue}
            onChange={handleOnChange}
            className={cx(props.rounded ? 'rounded-full' : 'rounded-md', classNameInput)}
            size={size}
            fullwidth
            disabled={disabled}
            enableTime={enableTime}
            format={format}
            disabledDate={props.disabledDate}
            {...props}
          />
        </Form.Item>
      )
    }

    if (type === 'dateRange') {
      const dateValue = inputValue ? inputValue : []
      return (
        <div ref={inputRef}>
          <Form.Item className={cx([className])} {...formItemProps}>
            <AppDateRangePicker
              value={dateValue}
              onChange={handleOnChangeDateRange}
              className={cx([classNameInput, 'rounded-md'])}
              size={size}
              enableTime={enableTime}
              disabled={disabled}
              placement={dateRangePlacement}
              format={format}
              {...props}
            />
          </Form.Item>
        </div>
      )
    }

    if (type === 'time') {
      return (
        <Form.Item className={cx([className])} {...formItemProps}>
          <AppDatePicker
            value={inputValue}
            onChange={handleOnChange}
            className={cx([classNameInput, 'rounded-md'])}
            size={size}
            fullwidth
            disabled={disabled}
            format="HH:mm"
            {...props}
          />
        </Form.Item>
      )
    }

    if (type === 'textArea') {
      return (
        <Form.Item className={cx([className, 'form-text-area'])} {...formItemProps}>
          <Input.TextArea
            disabled={disabled}
            value={inputValue}
            onChange={handleOnChange}
            onBlur={onBlur}
            className={cx([classNameInput, 'rounded-md'])}
            rows={rows}
            onPressEnter={handleOnPressEnter}
            showCount={!disabled}
            maxLength={maxLength}
          />
        </Form.Item>
      )
    }

    if (type === 'checkbox') {
      return (
        <Form.Item className={cx([className, 'form-checkbox'])} {...formItemProps}>
          <AppInputGroup
            disabled={disabled}
            type={type}
            className="custom-checkbox flex flex-wrap"
            classNameInput={cx(['custom-checkbox items-start mr-16 pb-4', classNameInput])}
            options={options}
            value={inputValue}
            onChange={handleOnChange}
            {...props}
          />
        </Form.Item>
      )
    }

    if (type === 'checkboxSingle') {
      return (
        <Form.Item
          label={labelForCheckBox}
          className={className}
          name={name}
          valuePropName="checked"
          labelCol={formItemProps.labelCol}
          wrapperCol={formItemProps.wrapperCol}
        >
          <AppCheckbox
            checked={inputValue}
            onChange={handleOnChange}
            disabled={disabled}
            className={classNameInput}
            {...props}
          >
            <Flex>
              {label} {suffixLabel}
            </Flex>
          </AppCheckbox>
        </Form.Item>
      )
    }

    if (type === 'radio') {
      return (
        <Form.Item className={cx([className, 'form-radio'])} {...formItemProps}>
          <AppInputGroup
            disabled={disabled}
            type={type}
            className="flex flex-wrap"
            classNameInput={cx(['custom-radio items-start mr-4 pb-4', classNameInput])}
            options={options}
            value={inputValue}
            onChange={handleOnChange}
            {...props}
          />
        </Form.Item>
      )
    }

    if (type === 'image') {
      return (
        <Form.Item className={cx([className])} {...formItemProps}>
          <AppFileStaticUpload {...props} onChange={onChange} />
        </Form.Item>
      )
    }

    if (type === 'password') {
      return (
        <Form.Item className={cx([className])} {...formItemProps}>
          <Input.Password
            value={inputValue}
            onChange={handleOnChange}
            onBlur={onBlur}
            className={cx([classNameInput, 'rounded-md'])}
            size={size as SizeType}
            disabled={disabled}
            onPressEnter={handleOnPressEnter}
            {...props}
          />
        </Form.Item>
      )
    }

    return <></>
  },
)
