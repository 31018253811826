import { INITIAL_ID } from '@components/CreditRequest/type'
import { AustraliaTimeZones } from '@consts/timezones'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ActivityType } from '@modules/realtime-notification/types'
import * as Sentry from '@sentry/browser'
import { ApiService } from '@services/ApiService'
import localAccountManager from '@services/localAccountManager'
import * as authService from '@services/oauthService'
import { logout } from '@services/oauthService'
import LocalStorageManager from '@services/storage/LocalStorageManager'
import { BUSINESS_TYPE, CONTRACTOR_TYPE, FROM_MODULE_TYPE, ROLE_NAME, TIMEZONE_LIST } from '@utils/constants'
import { showNotification } from '@utils/errors'
import { initFavicon } from '@utils/functions'
import dayjs from 'dayjs'
import { action, computed, makeAutoObservable } from 'mobx'
import notificationService from 'modules/realtime-notification/realtimeNotificationService'
import moment, { Moment } from 'moment-timezone'
import { initHelpDeskSystem } from 'tracking/helpDeskSystem'

class AppStore {
  constructor() {
    makeAutoObservable(this, {
      defaultSenderEmail: computed,
    })
  }

  appInsights: ApplicationInsights = null
  //#region Props
  states = []
  currentUser = {
    id: null,
    roles: [] as string[],
    dateFormat: 'DD MMM YYYY',
    caseManager: {} as any,
    contractor: {} as any,
    specialist: {} as any,
    email: null,
    displayName: null,
    extraProperties: null as any,
    firstName: null,
    lastName: null,
    fullName: null,
    name: null,
    isActive: null,
    userName: null,
    surname: null,
    subscription: null as any,
    tenantId: null,
    title: null,
    company: null,
    contractorCompany: null,
    caseManagerCompany: null,
  }
  quickSetting = false
  authenticated = false
  isImpersonated = false
  loaded = false
  isTenantAdmin = false
  isAdminStaff = false
  isCaseManager = false
  isSpecialist = false
  isStaff = false
  isContractor = false
  isAccounting = false
  isMagStaff = false
  isInsurerContractor = false
  isBrokerContractor = false
  isAdminOrStaff = false
  isAdminOrStaffOrCM = false
  isAdminOrStaffOrSpecialist = false
  labelOperator = 'MLP'
  currentTenant = {
    id: null,
    name: null,
    features: {} as any,
    country: {} as any,
    editionName: null,
    extraProperties: {} as any,
    settings: {} as any,
    timezone: null,
    defaultCountryId: null,
    defaultPicId: null,
    defaultPic: null,
  }

  hostTenant = {
    id: null,
    name: null,
    features: null as any,
    country: {} as any,
    editionName: null,
    extraProperties: {} as any,
    settings: {} as any,
    timezone: null,
    defaultCountryId: null,
    defaultPicId: null,
    defaultPic: null,
  }
  timezone = ''
  features = {
    Assessment: false,
    MedNeg: false,
    FileReview: false,
    Supplementary: false,
    MedicalRecord: false,
    MarketingCampaign: false,
    TreatmentProvider: false,
    MassTorts: false,
    OT: false,
    Disbursement: false,
    DisbursementFunding: false,
    InsuranceClaim: false,
    FundingTransaction: false,
    NDIS: false,
    TimeTracking: false,
    InquiryCentre: false,
    LegalPracticeAPI: false,
    CaseManagement: false,
    MVA: false,
    LMQ: false,
    ICareClaim: false,
    MagExtraResources: false,
    AdHocTemplate: false,
  }
  edition = {
    MLP: false,
    LAW_CONNECT: false,
    FUNDING_PROVIDER: false,
    LMQ_PROVIDER: false,
    MARKETING: false,
  }
  settings = {}
  isOpenConfirmModal = false
  isOpenWarningWithoutSave = false
  warningWithoutSaveParams = {}
  confirmModalParams = {}
  showThemeSetting = false
  theme = localStorage.getItem('theme') || 'dark'

  enableHint = localStorage.getItem('ENABLE_HINT') === 'true' ? true : false
  logoColor = localStorage.getItem('LOGO_COLOR') ? JSON.parse(localStorage.getItem('LOGO_COLOR')) : null

  get postCodeLabel() {
    return this.currentTenant.country?.name === 'United States' ? 'Zipcode' : 'Postcode'
  }
  get isRingCentralEmbeddable() {
    return this.currentTenant?.settings['ICS.RingCentral.EnableRingCentral']?.toLowerCase() == 'true'
  }
  get isCountryUS() {
    return this.currentTenant.country?.name === 'United States'
  }

  get isHostTenant() {
    return this.currentTenant?.id == INITIAL_ID
  }

  get isFundingProviderInsurerCM() {
    return this.edition.FUNDING_PROVIDER && this.isCaseManager
  }

  get isLawConnectOrCM() {
    return this.edition.LAW_CONNECT || this.isCaseManager
  }

  get isDemoAccount() {
    return this.currentTenant.name === 'MLP Demo' && !this.isAdminStaff
  }

  get isMAGTenant() {
    return this.currentTenant.name == 'MAG'
  }

  get colorLogo() {
    if (this.logoColor) {
      return this.logoColor
    }
    const tenantProperties = this.isHostTenant ? this.hostTenant?.extraProperties : this.currentTenant?.extraProperties
    return {
      LogoBackgroundColor: tenantProperties?.LogoBackgroundColor || 'none',
      LoginLogoBackgroundColor: tenantProperties?.LoginLogoBackgroundColor || 'none',
    }
  }

  get defaultSenderEmail() {
    return appStore.currentTenant.settings['ICS.Office365.Enable']
      ? appStore.currentTenant.settings['ICS.Office365.SenderEmail']
      : appStore.currentTenant.settings['ICS.SendGrid.SenderEmail']
  }

  //#region Application Insight Tracking
  trackPageView = (pageName: string, uri: string) => {
    this.appInsights?.trackPageView({
      name: pageName,
      uri: uri,
      properties: {
        tenantId: this.currentTenant?.id,
        userId: this.currentUser?.id,
        tenantName: this.currentTenant?.name,
      },
    })
  }
  startTrackPage = (pageName: string) => {
    this.appInsights?.startTrackPage(pageName)
  }

  stopTrackPage = (pageName: string) => {
    const currentUrl = document.location.href
    this.appInsights?.stopTrackPage(pageName, currentUrl, {
      tenantId: this.currentTenant?.id,
      userId: this.currentUser?.id,
      tenantName: this.currentTenant?.name,
    })
  }
  //#endregion
  setEnableHint = (enable: boolean) => {
    localStorage.setItem('ENABLE_HINT', enable ? 'true' : 'false')
    this.enableHint = enable
  }

  setTimeZone = timeZone => {
    this.timezone = timeZone
    window.APP_CONFIG.timeZone = timeZone
  }
  async init() {
    const user = await this.getCurrentUser()
    if (!user) return

    let currentTenant
    try {
      currentTenant = await ApiService.Tenant.GetById('myTenantSetting')
    } catch {
      localAccountManager.removeCurrentAccount()
      logout()
    }

    this.appInsights?.setAuthenticatedUserContext(`userId:${user.id}_tenant:${currentTenant?.data?.id}`)

    // initSessionInfo(user, currentTenant?.data)

    if (this.isAdminOrStaff) {
      initHelpDeskSystem(currentTenant?.data, this.currentUser)
    }

    action(() => {
      if (currentTenant?.data) {
        this.currentTenant = currentTenant.data
        this.validateTenantAndDomain()
        this.updateCurrentLoginAccount()

        initFavicon(this.currentTenant.extraProperties?.Favicon)
        this.features = {
          Assessment: this.currentTenant.features['Application:Assessment'] === 'True',
          Supplementary: this.currentTenant.features['Application:Supplementary'] === 'True',
          FileReview: this.currentTenant.features['Application:FileReview'] === 'True',
          MedicalRecord: this.currentTenant.features['Application:MedicalRecord'] === 'True',
          MedNeg: this.currentTenant.features['Application:MedNeg'] === 'True',
          MarketingCampaign: this.currentTenant.features['Application:MarketingCampaign'] === 'True',
          TreatmentProvider: this.currentTenant.features['Application:TreatmentProvider'] === 'True',
          MassTorts: this.currentTenant.features['Application:MassTorts'] === 'True',
          OT: this.currentTenant.features['Application:OT'] === 'True',
          Disbursement: this.currentTenant.features['Application:Disbursement'] === 'True',
          DisbursementFunding: this.currentTenant.features['Application:DisbursementFunding'] === 'True',
          InsuranceClaim: this.currentTenant.features['Application:InsuranceClaim'] === 'True',
          FundingTransaction: this.currentTenant.features['Application:FundingTransaction'] === 'True',
          NDIS: this.currentTenant.features['Application:NDIS'] === 'True',
          TimeTracking: this.currentTenant.features['Application:TimeTracking'] === 'True',
          InquiryCentre: this.currentTenant.features['Application:InquiryCentre'] === 'True',
          LegalPracticeAPI: this.currentTenant.features['Application:LegalPracticeAPI'] === 'True',
          CaseManagement: false,
          MVA: this.currentTenant.features['Application:MVA'] === 'True',
          LMQ: this.currentTenant.features['Application:LMQ'] === 'True',
          ICareClaim: this.currentTenant.features['Application:ICareClaim'] === 'True',
          MagExtraResources: this.currentTenant.features['Application:MagExtraResources'] === 'True',
          AdHocTemplate: this.currentTenant.features['Application:AdHocTemplate'] === 'True',
        }

        this.features.CaseManagement = this.features.MassTorts || this.features.NDIS || this.features.MVA

        // Set edition
        const { editionName } = this.currentTenant
        switch (editionName) {
          case 'MLP':
            this.edition.MLP = true
            break
          case 'LAW CONNECT':
            this.edition.LAW_CONNECT = true
            break
          case 'FUNDING PROVIDER':
            this.edition.FUNDING_PROVIDER = true
            break
          // case 'MLEA':
          //   this.edition.MLEA = true
          //   break
          case 'LMQ PROVIDER':
            this.edition.LMQ_PROVIDER = true
            break
          case 'MARKETING':
            this.edition.MARKETING = true
            break
          default:
            break
        }
      }
      this.setTimeZone(this.currentTenant.timezone ?? moment.tz.guess())
      this.loaded = true
    })()
    LocalStorageManager.loadUserTableSettings()
  }

  addBusinessDays = (numDays, startDate) => {
    let resultDate = new Date(startDate)
    let businessDaysAdded = 0

    while (numDays > 0) {
      resultDate.setDate(resultDate.getDate() + 1)

      // Check if the current day is a business day (Monday to Friday)
      businessDaysAdded++
      if (resultDate.getDay() !== 0 && resultDate.getDay() !== 6) {
        numDays--
      }
    }
    return businessDaysAdded
  }

  validateTenantAndDomain() {
    let tenantDomain = this.currentTenant?.extraProperties.ClientDomain ?? ''

    if (!tenantDomain.startsWith('https://')) {
      tenantDomain = 'https://' + tenantDomain
    }
    const defaultDomain = process.env.VITE_APP_DOMAIN
    const validDomains = [tenantDomain, defaultDomain, 'http://localhost:3000'].filter(i => i)
    if (!validDomains.includes(location.origin)) {
      console.error('Domain is not valid: ' + location.origin + '. Please check config VITE_APP_DOMAIN')
      authService.logout()
    }
  }
  async getCurrentUser() {
    try {
      const resp = await ApiService.Profile.getProfile()
      const user = { ...resp.data, roles: resp.data.roles.map(i => i.name) }
      action(() => {
        this.currentUser = user
        this.authenticated = true
        this.isTenantAdmin = user.roles.includes(ROLE_NAME.TENANT_ADMIN)
        this.isAdminStaff = user.roles.includes(ROLE_NAME.ADMIN_STAFF)
        this.isCaseManager = user.roles.includes(ROLE_NAME.CASE_MANAGER) // test purpose
        this.isSpecialist = user.roles.includes(ROLE_NAME.SPECIALIST)
        this.isStaff = user.roles.includes(ROLE_NAME.STAFF)
        this.isContractor = user.roles.includes(ROLE_NAME.CONTRACTOR)
        this.isAccounting = user.roles.includes(ROLE_NAME.ACCOUNTING)

        this.isAdminOrStaff = this.isAdminStaff || this.isStaff || this.isAccounting
        this.isAdminOrStaffOrSpecialist = this.isAdminStaff || this.isStaff || this.isAccounting || this.isSpecialist
        this.isAdminOrStaffOrCM = this.isAdminStaff || this.isStaff || this.isAccounting || this.isCaseManager
        this.isImpersonated = false

        this.isBrokerContractor = this.isContractor && user.contractor?.type == CONTRACTOR_TYPE.BROKER

        this.isInsurerContractor =
          this.isContractor &&
          user.contractor?.type != CONTRACTOR_TYPE.BROKER &&
          user.contractorCompany?.businessType == BUSINESS_TYPE.INSURER
      })()
      return resp?.data
    } catch (err) {
      logout()
      return false
    }
  }

  private updateCurrentLoginAccount() {
    const currentLoginAccount = localAccountManager.currentAccount
    currentLoginAccount.tenantName = this.currentTenant.name
    localAccountManager.saveAccount(currentLoginAccount)
  }

  hasFeature(featureName) {
    return this.currentTenant.features[featureName] === 'True'
  }

  isHostAdmin() {
    return this.currentTenant?.id == INITIAL_ID && this.isTenantAdmin
  }

  isHostAdminStaff() {
    return this.currentTenant?.id == INITIAL_ID && this.isAdminStaff
  }

  isHostStaff() {
    return this.currentTenant?.id == INITIAL_ID && this.isStaff
  }

  isBorrower = () => {
    return this.edition.LAW_CONNECT || this.edition.MLP
  }

  checkRolesCurrentUser = (roles = []) => {
    const user = this.currentUser
    const role = user?.roles?.filter(i => roles.indexOf(i) !== -1)
    return !!role?.length
  }

  isEnableServiceFeeEstimation = () => {
    return (this.currentTenant?.settings?.['ICS.Feature.ServiceFeeEstimation'] ?? '').toLowerCase() === 'true'
  }

  /**
   *
   * @param {*} datetime
   * @param {*} mode => start or end
   * @param {*} timezone
   * @returns Datetime UTC string
   */
  convertFilterTimeToUTC(datetime, mode = 'start', timezone?) {
    const currentTimeZone = timezone || dayjs.tz.guess() // this.timezone
    if (datetime) {
      if (mode === 'start') {
        return dayjs(datetime).tz(currentTimeZone).startOf('day').toISOString()
      } else if (mode === 'end') {
        return dayjs(datetime).tz(currentTimeZone).endOf('day').toISOString()
      } else {
        return dayjs(datetime).tz(currentTimeZone).toISOString()
      }
    }

    return null
  }

  formatDate(datetime, timeZone?, format?, skipTimezone = false) {
    const dateFormat = format || this.currentUser?.dateFormat
    const configTimeZone = timeZone || dayjs.tz.guess() // this.timezone
    if (datetime) {
      if (skipTimezone) {
        return dayjs(datetime).format(dateFormat)
      }
      return dayjs(datetime).tz(configTimeZone).format(dateFormat)
    }
    return ''
  }

  formatDateIgnoreTimezone(datetime, format?) {
    const dateFormat = format || this.currentUser?.dateFormat
    if (datetime) {
      return dayjs.utc(datetime).format(dateFormat)
    }
    return ''
  }

  formatDateTime(datetime, timezone?, format?, ignoreDST = false) {
    let dateFormat = format || this.currentUser.dateFormat + ' - HH:mm'

    if (typeof datetime === 'string') {
      return this.getMomentDateTime(datetime, timezone, { ignoreDST })?.format(dateFormat)
    }

    return this.formatDate(datetime, timezone, dateFormat)
  }

  getMomentDateTime(date, timezoneParam = null, { ignoreDST = false } = {}) {
    const timezoneValue = timezoneParam ? timezoneParam : dayjs.tz.guess() // this.timezone

    if (!date) return date

    if (typeof date === 'string') {
      date = dayjs(date)
    }

    let defaultOffset = dayjs().tz(timezoneValue).utcOffset()

    if (ignoreDST) {
      return date.utcOffset(defaultOffset)
    } else {
      return date.tz(timezoneValue)
    }
  }

  getTodayDate() {
    return dayjs().tz(this.timezone)
  }

  getMomentDate(date, skipTimezone = true) {
    if (!date) return date
    if (skipTimezone) {
      return dayjs(date).startOf('day')
    }
    return dayjs(date).tz(this.timezone).startOf('day')
  }

  /**
   *
   * @param {moment} momentDate
   * @param {string} fromTimeZone. Date created from this TimeZone
   * @param {string} toTimeZone View date at this TimeZone
   * @returns
   */
  getRelativeDate(momentDate: Moment, fromTimeZone, toTimeZone): Moment | null {
    try {
      const fromTimeZoneOffset = momentDate.tz(fromTimeZone).utcOffset()
      const toTimeZoneOffset = momentDate.tz(toTimeZone).utcOffset()

      const shiftOffset = toTimeZoneOffset - fromTimeZoneOffset

      const result = momentDate.utcOffset(toTimeZoneOffset)
      return result
    } catch {
      return null
    }
  }

  //#region Modal
  openConfirmModal(options) {
    this.isOpenConfirmModal = true
    this.confirmModalParams = options
  }
  openWarningExitWithoutSave = ({
    confirmConfig = {},
    discardChange = () => {},
    onSave,
    onCancel,
  }: {
    confirmConfig?: { confirmMessage?: string; saveLabel?: string }
    discardChange: () => void
    onSave: () => void
    onCancel?: () => void
  }) => {
    this.isOpenWarningWithoutSave = true
    this.warningWithoutSaveParams = {
      confirmMessage: confirmConfig?.confirmMessage ?? 'Do you want to save changes before opening new section?',
      discardChange,
      onSave,
      onCancel,
      saveLabel: confirmConfig?.saveLabel ?? 'Save',
    }
  }
  closeWarningExitWithoutSave = () => {
    this.isOpenWarningWithoutSave = false
    this.warningWithoutSaveParams = {}
  }
  closeConfirmModal() {
    this.isOpenConfirmModal = false
    this.confirmModalParams = {}
  }
  //#endregion

  resendPassword({ userId }: { userId?: string }) {
    if (!userId) return
    this.openConfirmModal({
      message: 'Do you want to reset password? New password will be sent to the user automatically.',
      onOk: async () => {
        ApiService.User.ResetPassword(userId)
          .then(async res => {
            if (res) {
              showNotification('Success', 'Resend login Information successfully!', 'success')
            }
          })
          .catch(err => {})
      },
    })
  }
  openCloseQuickSetting() {
    this.quickSetting = !this.quickSetting
  }

  captureError(message: string) {
    Sentry.captureMessage(message)
  }

  showSuccessMessage = {
    add: () => {
      showNotification('Success', 'Data has been added successfully', 'success')
    },
    save: () => {
      showNotification('Success', 'Data has been saved successfully.', 'success')
    },
    delete: () => {
      showNotification('Success', 'Data has been deleted successfully.', 'success')
    },
    customMessage: (message: string) => {
      showNotification('Success', message, 'success')
    },
  }

  previewEmailTemplateData = {
    isOpen: false,
    fromModule: null,
    fromModuleId: null,
    name: null,
    data: {
      detail: null,
      preview: null,
    },
    mvaClaimId: null,
    params: null,
    isShowEditTemplateTab: true,
    // modalWidth: 1000,
  }

  showPreviewEmailTemplate(data: typeof this.previewEmailTemplateData) {
    this.previewEmailTemplateData = data
  }

  hidePreviewEmailTemplate() {
    this.previewEmailTemplateData.isOpen = false
    this.previewEmailTemplateData.data.detail = null
    this.previewEmailTemplateData.data.preview = null
    this.previewEmailTemplateData.fromModule = null
    this.previewEmailTemplateData.fromModuleId = null
    this.previewEmailTemplateData.name = null
    this.previewEmailTemplateData.params = null
    this.previewEmailTemplateData.mvaClaimId = null
  }

  openMagConnectUrl(url: string) {
    window.open(`${process.env.VITE_MAG_CONNECT_DOMAIN}${url}`, '_blank')
  }

  logActivity(fromModule: FROM_MODULE_TYPE, objectId: string, activityType: ActivityType) {
    notificationService.logActivity(fromModule, objectId, activityType)
  }

  checkSpecialistHadContractDate = (specialist, functionCallbackCancel) => {
    if (!specialist) return
    if (
      !specialist?.onboardedDate ||
      dayjs().isBefore(dayjs(specialist?.onboardedDate)) ||
      (specialist?.onboardedEndDate && dayjs().isAfter(dayjs(specialist?.onboardedEndDate)))
    ) {
      appStore.openConfirmModal({
        message: 'This specialist does not have a contract with us. Do you want to continue?',
        onOk: () => {},
        functionCallbackCancel: functionCallbackCancel,
      })
    }
  }

  getTimeZoneDisplayName(dateString, timeZone) {
    if (!timeZone || !dateString) return null
    const timezoneInfo = TIMEZONE_LIST.find(i => i.id === timeZone)
    if (!timezoneInfo) return null

    if (this.currentTenant?.defaultCountryId === 16) {
      const timezoneParts = timeZone.split('/')
      const state = timezoneParts.length > 1 ? timezoneParts[1] : timezoneParts[0]
      // if country is Australia
      const auTimeZone = AustraliaTimeZones.find(i => i.name === timeZone)

      if (auTimeZone) {
        const isDST = moment(dateString).tz(timeZone)?.isDST()
        return `(${isDST ? auTimeZone.daylightTime : auTimeZone.standardTime}, ${state})`
      }
    }

    return `(${timezoneInfo.gmt} ${timeZone})`
  }

  setLogoColor = (key = '', value) => {
    if (!key) return

    const color = {
      ...this.colorLogo,
      [key]: value,
    }
    this.logoColor = color
    localStorage.setItem('LOGO_COLOR', JSON.stringify(color))
  }

  initTheme = () => {
    const theme = this.getCurrentTheme()
    this.setTheme(theme)
  }

  setTheme = (type = 'dark') => {
    const root = document.documentElement
    if (!root.style) return

    switch (type) {
      case 'dark':
        root?.style.setProperty('--sidebar-background-color', '#2d2d2d')
        root?.style.setProperty('--sidebar-color-text', '#ffffff')
        break

      case 'light':
        root?.style.setProperty('--sidebar-background-color', '#ffffff')
        root?.style.setProperty('--sidebar-color-text', '#000000')
        break

      case 'dark-blue':
        root?.style.setProperty('--sidebar-background-color', '#233D4D')
        root?.style.setProperty('--sidebar-color-text', '#ffffff')
        break

      default:
        break
    }
    localStorage.setItem('theme', type)
    this.theme = type
  }

  getCurrentTheme = () => {
    return localStorage.getItem('theme') || 'dark'
  }
}

const appStore = new AppStore()

export default appStore
