import './Menu.scss'
import cx from 'classnames'
import { observer } from 'mobx-react'
import React, { memo, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import dashboardStore from '@stores/dashboard'
import DashboardRoutes, { DashboardRouteService } from '../../../routes-setting/dashboardRoute'
import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons'
import { useLocation } from 'react-router-dom'
import AppIcon from '@components/AppIcon'

const MenuItemComponent = ({
  path,
  name = null,
  title = null,
  materialIcon = null,
  noImage = null,
  ischildren = false,
  labelMenu = null,
  target = null,
  redirect = null,
  onClick = null,
}) => {
  const { collapsed } = dashboardStore.sidebar

  const routeConfig = DashboardRoutes[path]

  const location = useLocation()

  const handleClick = (e, path) => {
    e.preventDefault()
    if (onClick) {
      onClick(e)
      return
    }
    if (redirect) {
      e.preventDefault()
      window.open(redirect, '_blank')
    } else {
      const { title: routeTitle } = DashboardRoutes[path]
      dashboardStore.open(path, {
        title: title || routeTitle,
      })
    }
  }

  const renderLinkContent = () => {
    const { title: routeTitle, icon } = DashboardRoutes[path]
    const finalTitle = title || routeTitle
    return (
      <>
        <AppIcon
          className={cx([collapsed ? 'mx-auto h-5' : 'mr-4 w-6'], 'text-2xl')}
          name={noImage ? '' : name || icon}
          title={finalTitle}
          materialIcon={materialIcon || null}
        />

        {collapsed && <span className="menu-item__arrow hidden-menu absolute left-full top-4 arrow-left ml-2"></span>}
        <span
          className={cx([
            collapsed &&
              (ischildren
                ? ''
                : 'hidden-menu px-3 py-2 rounded-md ml-4 absolute left-full border border-[#E7E7E7] shadow-[2px_2px_4px_0px_#0000000D]'),
            'whitespace-nowrap',
          ])}
        >
          {finalTitle}
        </span>
      </>
    )
  }

  if (!DashboardRouteService.hasPathName(path)) {
    return null
  }

  if (target && target === '_blank') {
    return (
      <a
        href={path}
        target={target}
        className={cx(
          materialIcon
            ? `${collapsed ? 'py-3.5 mb-2' : 'px-5 py-3 mb-1'} relative menu-item--parent flex items-center`
            : 'menu-item',
          { disabled: routeConfig.disabled, labelMenu },
        )}
      >
        {renderLinkContent()}
      </a>
    )
  }

  const currentRoutePath = location.pathname
  const active = routeConfig.path === currentRoutePath || routeConfig.children?.includes(currentRoutePath)

  return (
    <Link
      onClick={e => handleClick(e, path)}
      to={path}
      className={cx(
        materialIcon
          ? `${collapsed ? 'py-3.5 mb-2' : 'px-5 py-3 mb-1'} relative menu-item--parent flex items-center`
          : 'menu-item',
        { active, disabled: routeConfig.disabled, labelMenu },
      )}
    >
      {renderLinkContent()}
    </Link>
  )
}

const MenuGroupComponent = ({ hidden = null, title = null, children = null, materialIcon = null, name }) => {
  const [isHidden, setIsHidden] = useState(hidden)
  const [top, setTop] = useState<string | number>(0)

  const elementRef = useRef(null)
  const observerRef = useRef(null)

  const toggle = () => {
    setIsHidden(!isHidden)
  }

  const customChildrenWithProps = children => {
    return React.Children.map(children, child => {
      if (React.isValidElement(child)) {
        return React.cloneElement<any>(child, { ischildren: 'true' })
      }
      return child
    })
  }

  const { collapsed: autohide } = dashboardStore.sidebar

  useEffect(() => {
    const element = elementRef.current
    if (element) {
      const observerElement = new ResizeObserver(mutationsList => {
        for (let mutation of mutationsList) {
          const computedStyle = getComputedStyle(mutation.target)
          const currentDisplay = computedStyle.getPropertyValue('display')

          if (isElementVisible(mutation.target)) {
            const rect = mutation.target.getBoundingClientRect()

            if (document.body.clientHeight - rect.y < rect.height && currentDisplay === 'block') {
              const cal = document.body.clientHeight - rect.y
              const top = rect.height - cal + 10
              setTop(`-${top}px`)
            }
          } else {
            setTop(0)
          }
        }
      })

      observerElement.observe(element)
      observerRef.current = observerElement
    }
    return () => {
      const observerElement: ResizeObserver = observerRef.current
      if (observerElement) {
        observerElement.disconnect()
      }
    }
  }, [])

  const isElementVisible = element => {
    return element.offsetWidth > 0 && element.offsetHeight > 0
  }
  return (
    <div className="relative menu-group--wrap">
      <div
        className={cx([autohide ? 'py-3.5 mb-2' : 'pl-5 py-3', 'mb-1 menu-group flex items-center justify-between'])}
        onClick={toggle}
      >
        <div className={cx([autohide && 'mx-auto', 'flex items-center'])}>
          {materialIcon ? (
            <AppIcon
              className={cx([autohide ? 'mx-auto h-5' : 'mr-4 w-6', 'text-2xl'])}
              name={name}
              title={title}
              materialIcon={materialIcon ? materialIcon : null}
            />
          ) : null}
          <span className={cx(['whitespace-nowrap', autohide && 'hidden-menu'])}>{title}</span>
        </div>
        {!autohide &&
          (isHidden ? <CaretRightOutlined className="mr-3 text-lg" /> : <CaretDownOutlined className="mr-3 text-lg" />)}
      </div>
      {autohide && <span className="menu-group__arrow hidden-menu absolute left-full top-4 arrow-left ml-2"></span>}
      <div
        ref={elementRef}
        style={{ top: top }}
        className={cx(
          [
            'menu-group__children',
            autohide ? `absolute left-full` : 'ml-11',
            autohide ? 'hidden-menu' : isHidden ? 'hidden-menu' : '',
          ],
          {
            autohide,
          },
        )}
      >
        <div
          className={cx([
            autohide && 'border border-[#E7E7E7] shadow-[2px_2px_4px_0px_#0000000D] rounded-md ml-4 overflow-hidden',
          ])}
          style={{
            backgroundColor: 'var(--sidebar-background-color)',
          }}
        >
          {customChildrenWithProps(children)}
        </div>
      </div>
    </div>
  )
}

const MenuItem = memo(observer(MenuItemComponent))

const MenuGroup = memo(observer(MenuGroupComponent))

export { MenuItem, MenuGroup }
