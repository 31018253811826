import { FROM_MODULE_TYPE } from '@utils/constants'
import { ApiRequest, GetBaseServiceFunctions } from '../baseService'

const BaseRoute = 'app/attachments'

const baseFunctions = GetBaseServiceFunctions(BaseRoute)

function GenerateAudioTranscription(id: string, language: string) {
  return ApiRequest.post(`${BaseRoute}/${id}/generateAudioTranscription?language=${language}`)
}
const MergeFiles = (data: MergeFileInputDto) => {
  return ApiRequest.post(`${BaseRoute}/MergeFiles`, data)
}

const GetAttachmentTypes = () => {
  return ApiRequest.get(`${BaseRoute}/attachmentTypes`)
}
const API = {
  ...baseFunctions,
  GenerateAudioTranscription,
  MergeFiles,
  GetAttachmentTypes,
}

export default API
export class MergeFileInputDto {
  objectId: string
  fromModuleType: FROM_MODULE_TYPE
  attachmentTypeEnums: any[]
  mergedAttachmentType: any
  fileName: string
}
