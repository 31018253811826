import './Tabs.scss'

import cx from 'classnames'
import { observer } from 'mobx-react'
import routeManager from '@stores/dashboard'
import Tab from './Tab'
import TabMore from './TabMore'

const Tabs = () => {
  const {
    tabs,
    maxTabs,
    sidebar: { collapsed: sidebarAutoHide },
  } = routeManager
  
  return (
    <div className="Tabs">
      <div className={cx('Tabs-Outer', { sidebarAutoHide })}>
        <div className="Tabs-Inner">
          {tabs.slice(0, maxTabs).map(({ path, id }, index) => (
            <Tab tabIndex={index} tabId={id} />
          ))}
          {tabs.length > maxTabs && <TabMore />}
        </div>
      </div>
    </div>
  )
}

export default observer(Tabs)
