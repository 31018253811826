import './ShowSidebarIcon.scss'
import { MenuUnfoldOutlined } from '@ant-design/icons'
import dashboardStore from '@stores/dashboard'

const ShowSidebarIcon = () => {
  const { toggleSidebar } = dashboardStore
  return (
    <div className="DashboardHeaderIcon ShowSidebarIcon text-white cursor-pointer" onClick={toggleSidebar}>
      <MenuUnfoldOutlined />
    </div>
  )
}

export default ShowSidebarIcon
