import appStore from '@stores/appStore'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import { ReactComponent as RefreshIcon } from 'assets/svg/refresh-cw.svg'
import { ReactComponent as SearchIcon } from 'assets/svg/search.svg'
import { ReactComponent as XIcon } from 'assets/svg/x.svg'
import { AppButton } from '../AppComponents'

type Props = {
  loading?: boolean
  disable?: boolean
  handleRefresh?: () => void
  handleReset?: () => void
  handleSubmit?: () => void
  handleExport?: () => void
  buttonSize?: SizeType
  addNewButton?: JSX.Element
}

const TableDefaultButton = ({
  loading = false,
  disable = false,
  handleSubmit,
  handleRefresh = null,
  handleReset = null,
  buttonSize = 'middle',
  handleExport = null,
  addNewButton = null,
}: Props) => {
  const iconSize = buttonSize === 'large' ? 18 : 14
  return (
    <div className="flex gap-2">
      <AppButton
        disabled={loading || disable}
        onClick={() => (handleSubmit || handleRefresh)()}
        color="blue"
        size={buttonSize}
        circle
      >
        <SearchIcon className="inline" width={iconSize} height={iconSize} />
      </AppButton>
      {handleRefresh && (
        <AppButton
          disabled={loading || disable}
          onClick={() => handleRefresh()}
          type="primary"
          color="green"
          shape="circle"
          size={buttonSize}
        >
          <RefreshIcon className="inline" width={iconSize} height={iconSize} />
        </AppButton>
      )}
      {handleReset && (
        <AppButton
          disabled={loading || disable}
          onClick={() => handleReset()}
          color="red"
          size={buttonSize}
          circle
          className=""
        >
          <XIcon className="inline" width={iconSize} height={iconSize} />
        </AppButton>
      )}
      {addNewButton && addNewButton}
      {(appStore.edition.FUNDING_PROVIDER || appStore.edition.MLP) && appStore.isAdminOrStaff && handleExport && (
        <AppButton
          onClick={() => handleExport()}
          disabled={loading}
          type="primary"
          color="green"
          rounded
          size={buttonSize}
          tooltip={<div>Data export will be in multiple files of 500 records each.</div>}
        >
          Export Search Results
        </AppButton>
      )}
    </div>
  )
}

export default TableDefaultButton
