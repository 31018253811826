import { ApiRequest, CreateParams, GetBaseServiceFunctions } from '../baseService'

const BaseRoute = 'app/appointment-sessions'

const baseFunctions = GetBaseServiceFunctions(BaseRoute)

const getSearchAppointment = params => {
  let qs = CreateParams(params)
  return ApiRequest.get(`${BaseRoute}/searchAppointment?${qs.join('&')}`)
}

function GetSessionPopup(specialistId, sessionId) {
  return ApiRequest.get(`${BaseRoute}/popup?specialistId=${specialistId}&sessionId=${sessionId}`)
}

function SearchClinicArea(searchTerm = '') {
  return ApiRequest.post(`${BaseRoute}/searchClinicArea?searchTerm=${searchTerm}`)
}

function DaySheetPrint(params = {}) {
  let qs = CreateParams(params)
  return ApiRequest.post(`${BaseRoute}/daySheetPrint?${qs.join('&')}`)
}

function SendCentreAvaialbe(params) {
  let qs = CreateParams(params)
  return ApiRequest.post(`${BaseRoute}/sendCentreAvaialbe?${qs.join('&')}`)
}

function SendSpecialistBookingDaysheets(params) {
  let qs = CreateParams(params)
  return ApiRequest.post(`${BaseRoute}/sendSpecialistBookingDaysheets?${qs.join('&')}`)
}

const Export = params => {
  let qs = CreateParams(params)
  return ApiRequest.post(`${BaseRoute}/export`, params, { responseType: 'arraybuffer' })
}

function ValidateDaySheetPrint(payload = {}) {
  let qs = CreateParams(payload)
  return ApiRequest.post(`${BaseRoute}/validateDaySheetPrint?${qs.join('&')}`)
}

function CheckDuplicate(data) {
  return ApiRequest.post(`${BaseRoute}/checkDuplicate`, data)
}

const ExportDaysheets = params => {
  let qs = CreateParams(params)
  return ApiRequest.get(`${BaseRoute}/exportDaysheet?${qs.join('&')}`, { responseType: 'arraybuffer' })
}
const ExportAppointmentAvailability = params => {
  return ApiRequest.post(`${BaseRoute}/export-appointment-availability`, params, { responseType: 'arraybuffer' })
}
const exportSessionHistory = () => {
  return ApiRequest.get(`${BaseRoute}/export-session-history`, { responseType: 'arraybuffer' })
}

const SyncFromAssessment = sessionId => {
  return ApiRequest.put(`${BaseRoute}/${sessionId}/syncFromAssessment`)
}
const API = {
  ...baseFunctions,
  getSearchAppointment,
  SearchClinicArea,
  DaySheetPrint,
  SendCentreAvaialbe,
  SendSpecialistBookingDaysheets,
  Export,
  ValidateDaySheetPrint,
  CheckDuplicate,
  ExportDaysheets,
  GetSessionPopup,
  ExportAppointmentAvailability,
  exportSessionHistory,
  SyncFromAssessment,
}

export default API
