import { observer } from 'mobx-react'
import { SwapOutlined } from '@ant-design/icons'

import dashboardStore from '@stores/dashboard'
import Tooltip from '../../../components/shared/Tooltip'
import appStore from '@stores/appStore'

const ToggleAutoHideIcon = () => {
  const {
    sidebar: { collapsed, visible },
    toggleSidebar,
  } = dashboardStore

  const renderTenantLabel = () => {
    let edition = ''
    if (appStore.edition.FUNDING_PROVIDER) {
      edition = 'FP'
    } else if (appStore.edition.LAW_CONNECT) {
      edition = 'LC'
    } else if (appStore.edition.MLP) {
      edition = 'MLP'
    } else if (appStore.edition.LMQ_PROVIDER) {
      edition = 'LMQ'
    } else {
      edition = 'MASTER'
    }

    return `${edition}${appStore.currentTenant.name ? '-' + appStore.currentTenant.name : ''}`
  }

  return (
    <div>
      <span
        className="DashboardHeaderIcon"
        style={{ left: 16, width: 250, marginTop: 4, fontSize: '16px', textAlign: 'left' }}
      >
        {renderTenantLabel()}
      </span>
      <Tooltip>
        <div onClick={toggleSidebar} className="DashboardHeaderIcon r-0 cursor-pointer">
          <SwapOutlined />
        </div>
      </Tooltip>
    </div>
  )
}

export default observer(ToggleAutoHideIcon)
