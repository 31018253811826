import { ApiRequest } from '../baseService'

const BaseRoute = 'app/practice-evolve-app'

const GetListMatters = (method, url, data?) => {
  return ApiRequest.post(`${BaseRoute}/matters`, {
    method: method,
    data: data,
    url: url,
  })
}

const GetClientMatters = (method, url, data?) => {
  return ApiRequest.post(`${BaseRoute}/clients`, {
    method: method,
    data: data,
    url: url,
  })
}

const GetMatterDetail = (id, tenantId) => {
  return ApiRequest.get(`${BaseRoute}/matters/${id}?tenantId=${tenantId ?? ''}`)
}

const GetMatterAccount = matterId => {
  return ApiRequest.get(`${BaseRoute}/mattersAccount/` + matterId)
}

const API = {
  GetListMatters,
  GetMatterDetail,
  GetMatterAccount,
  GetClientMatters,
}
export default API
