import { ApiRequest, GetBaseServiceFunctions, queryClient } from '../baseService'

const BaseRoute = 'app/clients'

const baseFunctions = GetBaseServiceFunctions(BaseRoute)

function CheckDuplicateEmail(params) {
  return ApiRequest.get(`${BaseRoute}/similarItemsByEmail`, {
    params,
  })
}

function CheckDuplicateLastName(params) {
  return ApiRequest.get(`${BaseRoute}/similarItemsByLastName`, {
    params,
  })
}

function CheckDuplicateFullName(params) {
  return ApiRequest.get(`${BaseRoute}/similarItemsByFullName`, {
    params,
  })
}

function AssessmentHistory(params) {
  return ApiRequest.get(`${BaseRoute}/getCaseByClient`, {
    params,
  })
}

function Enable(id) {
  queryClient.invalidateQueries([BaseRoute, id])
  return ApiRequest.put(`${BaseRoute}/${id}/enable`)
}

function Disable(id) {
  queryClient.invalidateQueries([BaseRoute, id])
  return ApiRequest.put(`${BaseRoute}/${id}/disable`)
}

function ExportSearchResult(payload = {}) {
  return ApiRequest.post(`${BaseRoute}/export`, payload, { responseType: 'arraybuffer' })
}
function DownloadUploadClientTemplate() {
  return ApiRequest.post(`${BaseRoute}/template-import`, {}, { responseType: 'arraybuffer' })
}
function UploadFiles(file) {
  return ApiRequest.post(`${BaseRoute}/import`, file)
}
const API = {
  ...baseFunctions,
  CheckDuplicateEmail,
  CheckDuplicateLastName,
  CheckDuplicateFullName,
  AssessmentHistory,
  Enable,
  Disable,
  ExportSearchResult,
  DownloadUploadClientTemplate,
  UploadFiles,
}

export default API
