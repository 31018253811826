import { DyteMeeting, DytePermissionsMessage } from '@dytesdk/react-ui-kit'
import { useDyteClient } from '@dytesdk/react-web-core'
import { ApiService } from '@services/ApiService'
import { useEffect } from 'react'

interface Props {
  token: string
  kawaconnVideoToken: string
}

// Reference: https://dyte.io/blog/building-timed-meetings

const MaMinutesDuration = 120 // 120 minutes
const KawaconnMeeting = (props: Props) => {
  const [meeting, initMeeting] = useDyteClient(null)
  useEffect(() => {
    const searchParams = new URL(window.location.href).searchParams
    const authToken = props.token ?? searchParams.get('authToken')
    if (!authToken) {
      alert("An authToken wasn't passed, please pass an authToken in the URL query to join a meeting.")
      return
    }

    initMeeting({
      authToken: authToken,
      defaults: {
        audio: false,
        video: false,
      },
    }).then(m => {
      // console.log('Meeting initialized', m)
      m.participants.joined.on('participantJoined', participant => {
        setTimeout(() => {
          ApiService.VideoMeeting.CloseMeeting(m.meta.meetingId, props.kawaconnVideoToken)
        }, MaMinutesDuration * 60 * 1000)
      })
    })
  }, [])

  return (
    <>
      <DytePermissionsMessage meeting={meeting} />
      {/* <div>
        <div style={{ backgroundColor: 'white' }} className="absolute top-0 left-0 z-10">
          <span>Duration: 120 minutes</span>
          <span>This meeting will self close in:</span>
          {totalMinutesLeft}
          <Countdown
            autoStart={false}
            date={Date.now() + 100000}
            controlled={true}
            onTick={props => {
              setTotalMinutesLeft(props.total)
            }}
          />
        </div>
      </div> */}
      <DyteMeeting meeting={meeting} />
    </>
  )
}

export default KawaconnMeeting
