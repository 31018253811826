import { ApiRequest, GetBaseServiceFunctions, queryClient } from '../baseService'

const BaseRoute = 'app/case-managers'

const baseFunctions = GetBaseServiceFunctions(BaseRoute)

function Enable(id) {
  queryClient.invalidateQueries([BaseRoute, id])
  return ApiRequest.put(`${BaseRoute}/${id}/enable`)
}

function Disable(id, data = {}) {
  queryClient.invalidateQueries([BaseRoute, id])
  return ApiRequest.put(`${BaseRoute}/${id}/disable`, data)
}

function ResendAccountOffer(id) {
  queryClient.invalidateQueries([BaseRoute, id])
  return ApiRequest.put(`${BaseRoute}/${id}/resendAccountOffer`)
}

function CheckDuplicateLastName(params) {
  return ApiRequest.get(`${BaseRoute}/similarItemsByLastName`, { params })
}

function CheckDuplicateEmail(params) {
  return ApiRequest.get(`${BaseRoute}/similarItemsByEmail`, { params })
}

function GetBookingHistory(params) {
  return ApiRequest.get(`${BaseRoute}/bookingHistory`, { params })
}

function AddOrUpdateMyDetail(payload) {
  queryClient.invalidateQueries([BaseRoute])
  return ApiRequest.put(`${BaseRoute}/AddOrUpdateMyDetail`, payload)
}
function UpdateEmailUsername(payload) {
  queryClient.invalidateQueries([BaseRoute])
  return ApiRequest.put(`${BaseRoute}/UpdateEmailUsername/${payload.id}`, payload)
}
function ExportSearchResult(payload = {}) {
  return ApiRequest.post(`${BaseRoute}/export`, payload, { responseType: 'arraybuffer' })
}
function DownloadUploadClientTemplate() {
  return ApiRequest.post(`${BaseRoute}/template-import`, {}, { responseType: 'arraybuffer' })
}
function UploadFiles(file) {
  return ApiRequest.post(`${BaseRoute}/import`, file)
}
const API = {
  ...baseFunctions,
  Enable,
  Disable,
  ResendAccountOffer,
  CheckDuplicateLastName,
  CheckDuplicateEmail,
  GetBookingHistory,
  AddOrUpdateMyDetail,
  ExportSearchResult,
  DownloadUploadClientTemplate,
  UploadFiles,
  UpdateEmailUsername,
}

export default API
